<template>
  <a-spin :spinning="loading">
    <facility-preview-details :entity="entity" :entity_type="entity_type" />
  </a-spin>
</template>

<script>
import { mapActions } from "vuex";
import { GET_ENTITY } from "@/store/actions";

import FacilityPreviewDetails from "@/components/facilities/FacilityPreviewDetails.vue";

export default {
  components: {
    FacilityPreviewDetails,
  },
  props: {
    entity_type: {
      type: String,
      required: false,
      default: "",
    },
    base_url: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      entity: {},
    };
  },
  created() {
    this.fetchEntity();
  },
  methods: {
    ...mapActions("tourist", {
      getEntity: GET_ENTITY,
    }),
    async fetchEntity() {
      try {
        this.loading = true;

        const response = await this.getEntity({
          entity_type: this.entity_type,
          entity_id: this.$route.params.id,
        });

        this.entity = response.entity;
      } catch (error) {
        this.$notification.error({ message: "Error fetching entity data" });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
